import React, { useEffect, useState } from "react";
import {
    Routes,
    Route,
    useNavigate,
    useLocation
} from "react-router-dom";

import Auth from "../services/Auth";

import Home from "../pages/Home";
import Subject from "../pages/Subject";

import "../App.css"
import Report from "../pages/E-Report";
import Achievement from "../pages/Achievement";
import Profile from "../pages/Profile";
import PersonalInformation from "../pages/PersonalInformation";
import { Navbar } from "./NavBar";
import ReportDetail from "../pages/E-ReportDetail";
import { ModalAccessDenied } from "./Modal";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ChangePassword from "../pages/ChangePassword";

const Content = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const data = JSON.parse(localStorage.getItem('data'))
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        console.log(location)
        if (!Auth.isLogin()) {
            navigate("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div style={{ background: "#FFF" }}>
            <Navbar />
            <ModalAccessDenied visible={showModal} setShowModal={setShowModal} />
            <div className="bottom-navbar mobile-only">
                <div style={{ cursor: "pointer" }} onClick={() => { navigate("/") }}>
                    <img src={location.pathname === "/" ? "image/home-active.png" : "image/home.png"}
                        alt="Home Icon" width={30} height={30}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                    />
                    <span style={{ color: location.pathname === "/" ? "#024102" : "#9AB39A" }}>
                        Home
                    </span>
                </div>

                <div style={{ cursor: "pointer"}} onClick={() => { navigate("/subject") }}>
                    <img src={location.pathname.includes("/subject") ? "image/subject-active.png" : "image/subject.png"}
                        alt="Report Icon" width={30} height={30}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                    />
                    <span style={{ color: location.pathname.includes("/subject") ? "#024102" : "#9AB39A" }}>
                        Subject
                    </span>
                </div>
                <div style={{ cursor: "pointer", padding: 30  }}
                    onClick={() => {
                        if (data?.is_pengunjung) {
                            setShowModal(true)
                        } else {
                            navigate("/e-report");
                            localStorage.clearItem('mapel_selected');
                        }
                    }}
                >

                    <div
                        style={{
                            position: "absolute", left: "calc(50% - 75px / 2)", top: "-30px",
                            borderRadius: "50%", filter: "drop-shadow(0px -4px 4px rgba(233, 138, 21, 0.1))", background: "#E98A15", width: 70, height: 70,
                            border: "solid 3px #F6D0A1"
                        }}
                    >
                        <img src="image/report_new.png" alt="Report Icon" width={30} height={30}
                            style={{ display: "block", margin: "auto", marginTop: 15 }}
                        />
                    </div>
                    <div style={{ marginTop: 30, color: location.pathname.includes("/e-report") ? "#E98A15" : "#F6D0A1" }}>
                        E-Report
                    </div>
                </div>
                <div style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (data?.is_pengunjung) {
                            setShowModal(true)
                        } else {
                            navigate("/achievement")
                        }
                    }}
                >
                    <img src={location.pathname === "/achievement" ? "image/achievement-active.png" : "image/achievement.png"}
                        alt="Achievement Icon" width={30} height={30}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                    />
                    <span style={{ color: location.pathname === "/achievement" ? "#024102" : "#9AB39A" }}>
                        Achieve
                    </span>
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => { navigate("/profile") }}>
                    <img src={location.pathname === "/profile" ? "image/profile-active.png" : "image/profile1.png"}
                        alt="Profile Icon" width={30} height={30}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                    />
                    <span style={{ color: location.pathname === "/profile" ? "#024102" : "#9AB39A" }}>
                        Profile
                    </span>
                </div>
            </div>

            <Routes style={{ display: "flex", justifyContent: "center" }}>
                <Route path="/" element={<Home data={data} />} />
                <Route path="/subject" element={<Subject data={data} />} />
                <Route path="/e-report" element={<Report data={data} />} />
                <Route path="/e-report/detail" element={<ReportDetail data={data} />} />
                <Route path="/achievement" element={<Achievement data={data} />} />
                <Route path="/profile" element={<Profile data={data} />} />
                <Route path="/personal-information" element={<PersonalInformation data={data} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy data={data} />} />
                <Route path="/change-password" element={<ChangePassword data={data} />} />
            </Routes>
        </div>
    );
}

export default (Content)